
import PersonLink from "~/components/EntityLink/components/PersonLink.vue";

export default {
  name: "SearchResultTableCellPerson",

  components: {
      PersonLink,
  },

  inheritAttrs: false,

  props: {

    /**
     * id
     */
    id: {
      type: [String, Number],
      required: true
    },

    /**
     * person name
     */
    name: {
      type: String,
      required: true
    },

    /**
     * interface: [{key, value}]
     */
    keyFigures: {
      type: Object,
      default: () => { return [] }
    },

    /**
     * interface: [{key, value}]
     */
    keyFigureLabels: {
      type: Array,
      default: () => { return [] }
    },
  }
};
